<template>
  <div class="breadcrumbs h5 cl-gray">
    <span v-for="link in paths" :key="link.route_link">
      <router-link :to="{ path:link.route_link, query: fixedUrlQuery}">
        {{ link.name | htmlDecode }}
      </router-link><span class="cl-anyrent"> &nbsp; | &nbsp; </span>
    </span>
    <span class="cl-mine-shaft">
      {{ current | htmlDecode }}
    </span>
  </div>
</template>

<script>
import { Breadcrumbs } from '@vue-storefront/core/modules/breadcrumbs/components/Breadcrumbs.ts'
import FixedFilters from '@vue-storefront/core/mixins/FixedFilters';

export default {
  mixins: [Breadcrumbs, FixedFilters]
}
</script>
