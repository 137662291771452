<template>
  <div class="row">
    <div v-if="showTitle" class="col-xs-12 flex mb20 justify-content-space-between align-items-center">
      <h1 class="h1-title">
        {{ `${pickupPoint.partnerName} - ${formatEmpty(pickupPoint.street)}` }}
      </h1>
      <cross-button v-if="showCloseButton" class="" @click="$emit('close')" />
    </div>
    <div class="col-sm-6 col-md-12">
      <div class="col-xs-12">
        <div class="row address">
          <div class="col-xs-12 pb10">
            <span class="bold color-gray">{{ $t('Address') }}:</span>
          </div>
          <div v-if="pickupPoint.street" class="col-xs-12 color-matterhorn">
            {{ `${pickupPoint.street} ${pickupPoint.house_number}` }}
          </div>
          <div v-if="pickupPoint.city" class="col-xs-12 color-matterhorn">
            {{ pickupPoint.city }}
          </div>
          <div v-if="pickupPoint.postal_code" class="col-xs-12 color-matterhorn">
            {{ pickupPoint.postal_code }}
          </div>
          <div v-if="pickupPoint.latitude && pickupPoint.longitude" class="col-xs-12 color-matterhorn pt5 pb30">
            <a target="_blank" :href="getPickupPointUrl()" class="cl-accent">{{ $t('Navigate to pickup point') }}</a>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row phone">
          <div class="col-xs-3 col-md-4">
            <span class="bold color-gray">{{ $t('Phone') }}:</span>
          </div>
          <div class="col-xs-8 color-matterhorn">
            <a :href="`tel:${pickupPoint.phone_number}`" class="cl-accent">{{ formatEmpty(pickupPoint.phone_number) }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-12 opening-hours">
      <div class="row pt20 pb10">
        <div class="col-xs-12 color-gray">
          <span class="bold">{{ $t('Opening hours') }}:</span>
        </div>
      </div>
      <div class="col-xs-12 pl50">
        <div v-for="oh in getOpeningHours()" :key="oh" class="row cl-accent" :class="{'current-day':oh.today}">
          <div class="col-xs-3 col-md-4">
            <span>{{ $t(oh.day) }}</span>
          </div>
          <div class="col-xs-9 col-md-8">
            {{ oh.value }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!!pickupPoint.note" class="col-xs-12 note-wrapper">
      <span class="bold color-gray block pb10">{{ $t('Note') }}:</span>
      <span class="cl-accent ">
        {{ pickupPoint.note }}</span>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import CrossButton from '../../CrossButton';
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'PickupPointInfo',
  components: { CrossButton },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    pickupPoint: {
      type: Object,
      required: true
    }
  },
  computed: {
    address () {
      return {
        street: this.pickupPoint.street,
        houseNumber: this.pickupPoint.house_number,
        city: this.pickupPoint.city,
        postalCode: this.pickupPoint.postal_code,
        country: this.pickupPoint.country
      };
    }
  },
  methods: {
    toOpeningHour (openingHour) {
      const momentDay = dayjs(openingHour.segments[0].opens)
      const today = momentDay.isSame(dayjs(), 'day');
      const namedDay = !openingHour.segments.length ? '-' : momentDay.format('dddd');
      const isLastChild = i => i === openingHour.segments.length - 1;
      let value = this.$t('Closed');
      if (!openingHour.closed) {
        value = openingHour.segments
          .map(s => ({ opens: dayjs(s.opens), closes: dayjs(s.closes) }))
          .sort((a, b) => a.opens.day() - b.opens.day())
          .reduce((acc, s, i) => `${acc}${s.opens.format('H:mm')} - ${s.closes.format('H:mm')}${isLastChild(i) ? '' : ', '}`, '');
      }
      return {
        today,
        day: namedDay,
        value: value,
        closed: openingHour.closed
      };
    },
    getPickupPointUrl () {
      return `https://www.google.com/maps/search/${this.pickupPoint.latitude},${this.pickupPoint.longitude}`;
    },
    getOpeningHours () {
      const openingHours = JSON.parse(this.pickupPoint.opening_hours);
      if (!(openingHours && openingHours.length)) {
        return [];
      }
      const sortByDay = (h1, h2) => {
        if (!h1.segments.length || !h2.segments.length) {
          return -1;
        }
        if (dayjs(h1.segments[0].opens).day() === 0) {
          return 1;
        }
        return dayjs(h1.segments[0].opens).day() - dayjs(h2.segments[0].opens).day();
      };
      return openingHours
        .sort(sortByDay)
        .map(this.toOpeningHour);
    },
    formatEmpty (string) {
      return string || '-';
    },
    getObjectValues (obj) {
      return Object.values(obj);
    }
  }
};
</script>

<style lang="scss" scoped>

@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-silver: color(silver);
$color-gray: color(gray);
$color-matterhorn: color(matterhorn);

.color-gray {
  color: $color-gray;
}

.color-matterhorn {
  color: $color-matterhorn;
}

.pickup-point-content {
  background-color: white;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 800px;
  padding-bottom: 50px;
  font-family: TTCommons-Regular, sans-serif;
}

.pickup-point-container {
  top: 0;
  left: 0;
  z-index: 4;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.current-day {
  color:#313131;
  font-weight: bold;
}

.h1-title {
  color: #4f4f4f;
  font-family: TTCommons-Regular, sans-serif;
  font-size: 24px;
  margin-left: -4px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
}

.bottom-button::v-deep .wrap {
  width: 100px;
  color: white;
}

.bottom-button--white::v-deep .wrap {
  background-color: $color-silver;
}

.bottom-button {
  min-width: unset;
  width: unset;
  padding-left: 0;
  padding-right: 0;
}

.navigate-button {
  width: 35px;
  left: 14px;
  top: 110px;
  z-index: 600;
  position: absolute;
}
.note-wrapper {
  margin-top: 20px;
  margin-left:-4px;
}

.cross-button {
  margin-right: -8px;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.bold {
  font-weight: bold;
}

.pt5 {
  padding-top: 5px;
}

.pt25 {
  padding-top: 25px;
}

.mt20 {
  margin-top: 20px;
}

.h30 {
  height: 30px;
}

.h50 {
  height: 40px;
}

.h400 {
  height: 400px;
}

.w400 {
  width: 400px;
}

@media (max-width: 768px) {
  .pickup-point-container {
    padding: 0;
  }
}

.bottom-panel {
  visibility: collapse;
}

@media (max-width: 572px) {
  .navigate-button {
    top: 90px;
    left: 11px;
  }

  .pickup-point-content {
    width: 100%;
    height: 100%;
  }
  .hidden {
    visibility: collapse;
  }
  .overlay {
    display: none;
  }

  .bottom-panel {
    visibility: visible;
    position: absolute;
    display: flex;
    justify-content: space-around;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 500;
  }

  .cross-button {
    position: absolute;
    z-index: 100;
    top: 25px;
    right: 40px;
  }

  .cross-button i {
    padding: 0;
  }
}

</style>
