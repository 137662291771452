<template>
  <div class="flex justify-content-flex-end cross-button">
    <button
      type="button"
      class="p0 brdr-none bg-cl-transparent close mr40"
      @click="$emit('click')"
    >
      <i class="material-icons cl-accent">
        close
      </i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CrossButton'
};
</script>

<style scoped>
.cross-button {
  z-index: 100;
}

</style>
