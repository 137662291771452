<template>
  <component
    :is="compontentType"
    :type="!link ? type : false"
    :to="redirectionLink"
    class="no-outline button-full block brdr-none w-100 px10 py20 weight-400 h4 cl-white sans-serif fs-medium"
    :class="{ 'no-underline pointer align-center border-box': link, 'disabled': disabled, 'button-disabled': disabled }"
    data-testid="subscribeSubmit"
    :disabled="disabled"
  >
    <span :class="getWrapClass()">
      <slot>
        Button
      </slot>
    </span>
  </component>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'
export default {
  name: 'ButtonFull',
  directives: { focusClean },
  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    wrapStyle: {
      type: String,
      required: false,
      default: 'button-full-wrap-default'
    }
  },
  computed: {
    compontentType () {
      return this.link ? 'router-link' : 'button'
    },
    redirectionLink () {
      return this.link ? this.localizedRoute(this.link) : null
    }
  },
  methods: {
    getWrapClass () {
      return `wrap ${this.wrapStyle}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled {
    // background-color: gray
  }
</style>

<style lang="scss" scoped>
  .button-full {
    min-width: 250px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }
  button {
    background: none;
    // background-image: url("/assets/button.svg");
  }
  a.button-full {
    background: none;
    // background-image: url("/assets/button.svg");
    min-width: 180px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    min-height: 50px;
    border: 0px;
  }
  .wrap {
    border-radius: 38px;
    padding: 12px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 1em;
    display: inline-block;
  }

  .button-full-wrap-default {
    background-color: var(--partner);
  }

  .button-full-wrap-partner {
    background-color: var(--partner);
  }

  .button-full-wrap-gray {
    background-color: var(--gray);
  }
</style>
